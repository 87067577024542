import { default as _91slug_93akSvivKfvcMeta } from "/home/forge/gunduzyem.com/pages/[slug].vue?macro=true";
import { default as addresswtPILf9WZIMeta } from "/home/forge/gunduzyem.com/pages/account/address.vue?macro=true";
import { default as _91id_93FV3oziHTiDMeta } from "/home/forge/gunduzyem.com/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93ThjsG8SBrnMeta } from "/home/forge/gunduzyem.com/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexnSnn3mXDGSMeta } from "/home/forge/gunduzyem.com/pages/account/orders/index.vue?macro=true";
import { default as reviewnzsBwY282JMeta } from "/home/forge/gunduzyem.com/pages/account/review.vue?macro=true";
import { default as _91id_93MXBXvqYnEeMeta } from "/home/forge/gunduzyem.com/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexRgEKIEGvaJMeta } from "/home/forge/gunduzyem.com/pages/account/statement/index.vue?macro=true";
import { default as userTBupimOHadMeta } from "/home/forge/gunduzyem.com/pages/account/user.vue?macro=true";
import { default as wishlistKg58hFEf5ZMeta } from "/home/forge/gunduzyem.com/pages/account/wishlist.vue?macro=true";
import { default as cartsMbYQprxB5Meta } from "/home/forge/gunduzyem.com/pages/cart.vue?macro=true";
import { default as indexn2sdser4ILMeta } from "/home/forge/gunduzyem.com/pages/checkout/index.vue?macro=true";
import { default as paymentZZhv4GkV2yMeta } from "/home/forge/gunduzyem.com/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientbolIvzLsN2Meta } from "/home/forge/gunduzyem.com/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/gunduzyem.com/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestMDbnMRlnfYMeta } from "/home/forge/gunduzyem.com/pages/customer/request.vue?macro=true";
import { default as successJ9rq0BaLbOMeta } from "/home/forge/gunduzyem.com/pages/customer/success.vue?macro=true";
import { default as iletisimHSvHl6wJrmMeta } from "/home/forge/gunduzyem.com/pages/iletisim.vue?macro=true";
import { default as indexnVqSeHX6CzMeta } from "/home/forge/gunduzyem.com/pages/index.vue?macro=true";
import { default as login776gI3cDOvMeta } from "/home/forge/gunduzyem.com/pages/login.vue?macro=true";
import { default as offerDxOM5Cqbe3Meta } from "/home/forge/gunduzyem.com/pages/offer.vue?macro=true";
import { default as status84YD6L32cnMeta } from "/home/forge/gunduzyem.com/pages/order/status.vue?macro=true";
import { default as trackrRYQIDNmFkMeta } from "/home/forge/gunduzyem.com/pages/order/track.vue?macro=true";
import { default as forgotcZ7fFVdcKbMeta } from "/home/forge/gunduzyem.com/pages/password/forgot.vue?macro=true";
import { default as resetEabFkGSdwnMeta } from "/home/forge/gunduzyem.com/pages/password/reset.vue?macro=true";
import { default as payment_45notificationjtHAODdkfgMeta } from "/home/forge/gunduzyem.com/pages/payment-notification.vue?macro=true";
import { default as summaryCNBQgGdTgYMeta } from "/home/forge/gunduzyem.com/pages/payment/summary.vue?macro=true";
import { default as registerRdsSZAtKDvMeta } from "/home/forge/gunduzyem.com/pages/register.vue?macro=true";
import { default as searchqSgGctiI6zMeta } from "/home/forge/gunduzyem.com/pages/search.vue?macro=true";
import { default as locations4AfF58o7mfMeta } from "/home/forge/gunduzyem.com/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93akSvivKfvcMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addresswtPILf9WZIMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93FV3oziHTiDMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93ThjsG8SBrnMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexnSnn3mXDGSMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewnzsBwY282JMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93MXBXvqYnEeMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexRgEKIEGvaJMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userTBupimOHadMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistKg58hFEf5ZMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/gunduzyem.com/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexn2sdser4ILMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentZZhv4GkV2yMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/gunduzyem.com/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/gunduzyem.com/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/gunduzyem.com/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/gunduzyem.com/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexnVqSeHX6CzMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: login776gI3cDOvMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerDxOM5Cqbe3Meta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/gunduzyem.com/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/gunduzyem.com/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotcZ7fFVdcKbMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetEabFkGSdwnMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/gunduzyem.com/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/gunduzyem.com/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerRdsSZAtKDvMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchqSgGctiI6zMeta || {},
    component: () => import("/home/forge/gunduzyem.com/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/gunduzyem.com/modules/locationsPage/pages/locations.vue")
  }
]